import React from "react"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import find from '@patomation/find'

import Layout from '../components/Layout'
import renderAst from './_renderAst.js'
import { ImageGrid } from '@patomation/react-ui-components'

const Default = ({
  data
}) => data ?
  <Layout
  {...find('frontmatter', data)}>
    <section>
    { renderAst( find('htmlAst', data) ) }

    <ImageGrid images={find('collection', data)} />
    </section>
  </Layout>
: null

Default.propTypes = {
  data: PropTypes.object
}

export default Default;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      htmlAst
      excerpt(pruneLength: 160)
      frontmatter {
        title
        description
        hero_image
        collection
      }
    }
  }
`
